import React, { useState } from 'react'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import { t } from '../../../common/i18n'
import { PostRevisionDatos } from '../api/postRevisionDatos'
import AppContext from '../../../common/context/context'
import { revisionDatos } from '../store/matricula/revisionMotoMatriculaAction'
import styles from './index.module.scss'
import SpinnerWithText from '../../../common/components/spinnerWithText/SpinnerWithText'
import SelectorFooterDesktop from '../../../common/components/FunnelSelector/Footers/SelectorFooterDesktop'
import Input from '../../../shared/components/Input/Input'
import InputDropDown from '../../../shared/components/Input/InputDropDown'
import WhiteBoxContainer from '../../../shared/components/WhiteBoxContainer'

export enum CilindradaEnum {
  'hasta_125cc' = 'Hasta 125cc',
  '126_450cc' = '126cc – 450cc',
  '451_750cc' = '451cc – 750cc',
  '751_1000cc' = '751cc – 1000cc',
  'mas_1000cc' = 'Más de 1.000cc ',
}
const DatosMotoSchema = yup.object().shape({
  matricula: yup
    .string()
    .trim()
    .matches(
      /^[0-9]{4}[A-Za-z]{3}/,
      t('revisionesMoto.errores.matricula_incorrect')
    )
    .required(t('revisionesMoto.errores.matricula_required')),
  cilindrada: yup
    .string()
    .oneOf(
      Object.keys(CilindradaEnum),
      t('revisionesMoto.errores.cilindrada_noexist')
    ),
})

const DatosMotoForm = () => {
  const { revisionMotoState, dispatch } = React.useContext(AppContext)
  const { loading } = revisionMotoState
  const [selectedCilindrada, setSelectedCilindrada] = useState<string>(null)

  const { handleSubmit, register, errors, setValue, getValues } =
    useForm<PostRevisionDatos>({
      mode: 'onSubmit',
      validationSchema: DatosMotoSchema,
    })

  const onSubmit = (data: PostRevisionDatos) => {
    dispatch(revisionDatos({ ...data, cilindrada: selectedCilindrada }))
  }

  const options = Object.entries(CilindradaEnum).map(([key, value]) => ({
    value: key,
    label: t(`revisionesMoto.${key}`) as string,
  }))

  const handleChangeCilindrada = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : null
    setValue('cilindrada', value)
    setSelectedCilindrada(value)
  }

  return (
    <div className={styles.ficha_container_izquierda_revision_moto}>
      <WhiteBoxContainer>
        <div className={styles.personaldata_container}>
          <div className={styles.form_container}>
            <h3>{t('revisionesMoto.step1_titulo')}</h3>
            <div className={styles.title}>{t('revisionesMoto.step1_text')}</div>

            <form
              className={styles.login__form}
              onSubmit={handleSubmit(onSubmit)}>
              {loading ? (
                <SpinnerWithText
                  loading={loading}
                  text={t('revisiones.step1_waiting')}
                />
              ) : (
                <div>
                  <div className={styles.matricula_input_show}>
                    <Input
                      placeholder={t('revision_moto.escriba_matricula')}
                      label={t('revisionesMoto.matricula')}
                      name="matricula"
                      refe={register}
                      errors={errors}
                    />
                  </div>
                  <InputDropDown
                    placeholder={t('revision_moto.selecciona_cilindrada')}
                    labelId="revisionesMoto.cilindrada"
                    name="cilindrada"
                    formRef={register}
                    errors={errors}
                    options={options}
                    searchable={false}
                    onChange={handleChangeCilindrada}
                  />
                </div>
              )}

              <SelectorFooterDesktop
                continueButton={true}
                onGoBack={undefined}
              />
            </form>
          </div>
        </div>
      </WhiteBoxContainer>
    </div>
  )
}

export default DatosMotoForm
