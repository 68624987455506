import React from 'react'
import { FieldErrors, FieldName } from 'react-hook-form/dist/types'
import Error from '../../../common/components/error/Error'
import FormError from '../../../common/components/FormError/FormError'
import styles from './index.module.scss'
import InfoIcon from '../../../common/components/infoIcon/InfoIcon'

interface WithFormError<T> {
  name: FieldName<T>
  errors: FieldErrors<T>
}
interface WithStringError {
  name: string
  errors: string
}
type withErrors<T> = WithFormError<T> | WithStringError

type InputProps<T> = {
  label: string | React.ReactFragment
  refe: React.LegacyRef<HTMLInputElement>
  type?: string
  defaultValue?: string
  inputSize?: string
  withInfoIcon?: boolean
  infoIconText?: string
  maxLength?: number
  placeholder?: string
} & withErrors<T>

function Input<T>({
  label,
  name,
  refe,
  errors,
  type,
  defaultValue,
  inputSize,
  withInfoIcon,
  infoIconText,
  maxLength,
  ...props
}: InputProps<T>) {
  return (
    <div className={styles.input_wrapper}>
      <label className={styles.label}>
        {label}
        {withInfoIcon ? <InfoIcon text={infoIconText} /> : null}
      </label>
      <div className={styles.input}>
        <input
          defaultValue={defaultValue || ''}
          name={name as string}
          type={type}
          ref={refe}
          maxLength={maxLength}
          className={styles[inputSize] || ''}
          {...props}></input>
        {typeof errors === 'string' ? (
          errors && <Error message={errors}></Error>
        ) : (
          <FormError errors={errors} field={name as FieldName<T>} />
        )}
      </div>
    </div>
  )
}

export default Input
