import React from 'react'
import styles from './index.module.scss'

interface props {
  children: JSX.Element | JSX.Element[]
  className?: string
}
export default function WhiteBoxContainer({ children, className }: props) {
  return (
    <div
      className={
        className
          ? `${styles.white_box_container} ${styles[className]}`
          : `${styles.white_box_container}`
      }>
      {children}
    </div>
  )
}
