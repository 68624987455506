import React from 'react'
import { t } from '../../../../src/common/i18n'
import styles from './index.module.scss'
import Aceite from './assets/aceite.svg'
import Filtro from './assets/moto.svg'
import Seguridad from './assets/seguridad.svg'

const RevisionCard = () => (
  <div className={styles.procesoCard_container}>
    <h4>{t('revisiones.nuestras_revisiones_incluyen.title')}</h4>
    <div className={styles.procesoCard_item}>
      <Aceite />
      <div className={styles.procesoCard_item_text}>
        {t('revisiones.nuestras_revisiones_incluyen.aceite')}
      </div>
    </div>
    <div className={styles.procesoCard_item}>
      <Filtro />
      <div className={styles.procesoCard_item_text}>
        {t('revisiones.nuestras_revisiones_incluyen.filtro')}
      </div>
    </div>
    <div className={styles.procesoCard_item}>
      <Seguridad />
      <div className={styles.procesoCard_item_text}>
        {t('revisiones.nuestras_revisiones_incluyen.seguridad')}
      </div>
    </div>
  </div>
)

export default RevisionCard
