import { graphql, Link } from 'gatsby'
import React from 'react'
import { BreadCrumbItems } from '../../../../src/common/components/breadCrumb/LocalizedBreadCrumb'
import CTACard from '../../../../src/common/components/CTACard/CTACard'
import Layout from '../../../../src/common/components/Layout'
import RecomendacionCard from '../../components/RecomendacionCard/RecomendacionCard'
import Title from '../../../../src/common/components/Title'
import { t, textWithLocale } from '../../../../src/common/i18n'
import '../../../../src/common/styles/singlePage.scss'
import styles from '../../../../src/common/templates/plantillas/plantillaLateral.module.scss'
import route from '../../../../src/common/utils/route'
import { findMenuEntry } from '../../constants/menu'
import { useSelectedMenu } from '../../helpers/customHooks/useSelectedMenu'
import { useLocation } from '@reach/router'
import DatosMotoForm from '../../../../src/moto/revisiones/components/DatosMotoForm'
import RevisionCard from '../../components/RevisionCard'

const PageTemplate = ({ data }) => {
  const location = useLocation()
  useSelectedMenu(data.distributivas.id_menu_principal)
  const breadCrumItems = [
    { display: t('bread_crumb.inicio'), link: route('index') },
  ] as BreadCrumbItems
  const principal = findMenuEntry(data.distributivas.id_menu_principal)
  if (principal) {
    breadCrumItems.push({
      display: t(principal.name),
      link: route(principal.route),
    })
  }

  breadCrumItems.push({
    display: data.distributivas[textWithLocale('titulo')],
  })
  const isMotoRevision = location.pathname === route('moto.revisiones.index')
  return (
    <Layout
      selector={false}
      breadCrumItems={breadCrumItems}
      seoData={data.seoData}>
      <div className={styles.ficha_container}>
        <Title text={data.distributivas[textWithLocale('titulo')]}></Title>
        <div className={styles.ficha_container_inferior}>
          <div className={styles.ficha_wrapper_izquierda}>
            {isMotoRevision && (
              <>
                <div className={styles.revisionCardMobile}>
                  <RevisionCard />
                </div>
                <div className={styles.ficha_container_izquierda_revision_moto}>
                  <DatosMotoForm />
                </div>
              </>
            )}
            <div className={styles.ficha_container_izquierda}>
              <div
                className={styles.ficha_texto_container}
                dangerouslySetInnerHTML={{
                  __html: data.distributivas[textWithLocale('intro')],
                }}></div>
              <div
                className={
                  data.distributivas.site_plantillas.length === 4 ||
                  data.distributivas.site_plantillas.length === 2
                    ? styles.links_container
                    : styles.links_container_xl
                }>
                {data.distributivas.site_plantillas.map((plantilla) => (
                  <div
                    className={styles.link}
                    key={plantilla.id_site_plantilla}>
                    <Link
                      className={styles.ficha_link}
                      to={plantilla[textWithLocale('nombre')]}>
                      <img
                        src={plantilla[textWithLocale('miniatura_url')]}
                        alt={plantilla[textWithLocale('titulo')]}
                        title={plantilla[textWithLocale('titulo')]}
                        className={styles.img}
                      />
                      <br />
                      {plantilla[textWithLocale('titulo')]}
                      <br />
                    </Link>
                    <div className={styles.link_text}>
                      {plantilla[textWithLocale('resumen')]}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={styles.ficha_texto_container}
                dangerouslySetInnerHTML={{
                  __html: data.distributivas[textWithLocale('contenido')],
                }}></div>
            </div>
          </div>

          <div className={styles.ficha_container_lateral}>
            {isMotoRevision && (
              <div className={styles.revisionCardDesktop}>
                <RevisionCard />
              </div>
            )}
            <RecomendacionCard
              recomendacion={
                data.distributivas[textWithLocale('recomendacion')]
              }
            />
            {data.distributivas.cta_cita_previa === 1 && (
              <CTACard
                description={
                  data.distributivas[textWithLocale('cta_descripcion')]
                }
                linkButton={data.distributivas[textWithLocale('cta_link')]}
                textButton={
                  data.distributivas[textWithLocale('cta')]
                    ? data.distributivas[textWithLocale('cta')].toUpperCase()
                    : ''
                }
                ctaLink={
                  data.distributivas.cta === 'cita_previa' &&
                  data.distributivas.menu_principal !== 'moto'
                    ? {
                        cta: data.distributivas.cta,
                        cta_es: data.distributivas.cta_es,
                        cta_ca: data.distributivas.cta_ca,
                      }
                    : null
                }
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query distributivaPageQuery($id_site_distributiva: Int!, $url: String!) {
    distributivas(id_site_distributiva: { eq: $id_site_distributiva }) {
      id_site_distributiva
      id_menu_principal
      nombre_es
      nombre_ca
      titulo_es
      titulo_ca
      intro_ca
      intro_es
      contenido_ca
      contenido_es
      recomendacion_ca
      recomendacion_es
      cta
      cta_es
      cta_ca
      cta_descripcion_ca
      cta_descripcion_es
      cta_link_es
      cta_link_ca
      menu_principal
      cta_cita_previa
      site_plantillas {
        id_site_plantilla
        nombre_es
        nombre_ca
        titulo_es
        titulo_ca
        resumen_ca
        resumen_es
        miniatura_url_es
        miniatura_url_ca
      }
    }
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
